import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Modal } from "../modal/Modal";
import { Button } from "../input/Button";
import { ReactComponent as EnterIcon } from "../icons/Enter.svg";
import { ReactComponent as VRIcon } from "../icons/VR.svg";
import { ReactComponent as ShowIcon } from "../icons/Show.svg";
import { ReactComponent as SettingsIcon } from "../icons/Settings.svg";
import styles from "./SatelliteCustomEntryModal.scss";
import styleUtils from "../styles/style-utils.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { Column } from "../layout/Column";
import { FormattedMessage } from "react-intl";
import { ToolbarButton } from "../input/ToolbarButton";

export function DynamicCustomEntryModal({
  appName,
  className,
  roomName,
  showJoinRoom,
  onJoinRoom,
  showEnterOnDevice,
  onEnterOnDevice,
  showSpectate,
  onSpectate,
  showOptions,
  onOptions,
  ...rest
}) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (window.SAT.Utils.DynamicCustomEntry) {
      setIsInitialized(true);
    }
  }, []);

  const breakpoint = useCssBreakpoints();
  const useLarge = breakpoint !== "sm" && breakpoint !== "md";
  const useSmall = breakpoint === "sm";
  const useMed = breakpoint === "md";
  const locale = window.SAT.Utils.locale;
  const isFrench = window.SAT.Utils.locale === "fr";
  const isEnglish = window.SAT.Utils.locale === "en";

  const options = {
    appName: appName,
    className: className,
    roomName: roomName,
    showJoinRoom: showJoinRoom,
    onJoinRoom: onJoinRoom,
    showEnterOnDevice: showEnterOnDevice,
    onEnterOnDevice: onEnterOnDevice,
    showSpectate: showSpectate,
    onSpectate: onSpectate,
    showOptions: showOptions,
    onOptions: onOptions,
    customEntryButtonStyles: styles.customentrybutton
  };

   return isInitialized ? (
    <div dangerouslySetInnerHTML={{ __html: SAT.Utils.DynamicCustomEntry.renderHTML(options) }} />
  ) :<ToolbarButton
  icon={<EnterIcon />}
  label={<FormattedMessage id="toolbar.join-room-button" defaultMessage="Join Room" />}
  preset="accept"
  onClick={SAT.onEnterRoom.onEnterRoom}
/> ;
}

DynamicCustomEntryModal.propTypes = {
  appName: PropTypes.string,
  title: PropTypes.string,
  subtitleA: PropTypes.string,
  subtitleB: PropTypes.string,
  logoSrc: PropTypes.string,
  className: PropTypes.string,
  roomName: PropTypes.string.isRequired,
  showJoinRoom: PropTypes.bool,
  onJoinRoom: PropTypes.func,
  showEnterOnDevice: PropTypes.bool,
  onEnterOnDevice: PropTypes.func,
  showSpectate: PropTypes.bool,
  onSpectate: PropTypes.func,
  showOptions: PropTypes.bool,
  onOptions: PropTypes.func
};

DynamicCustomEntryModal.defaultProps = {
  showJoinRoom: true,
  showEnterOnDevice: true,
  showSpectate: true,
  showOptions: true
};
