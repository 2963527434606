import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Modal } from "../modal/Modal";
import { Button } from "../input/Button";
import { ReactComponent as EnterIcon } from "../icons/Enter.svg";
import { ReactComponent as VRIcon } from "../icons/VR.svg";
import { ReactComponent as ShowIcon } from "../icons/Show.svg";
import { ReactComponent as SettingsIcon } from "../icons/Settings.svg";
import styles from "./SatelliteCustomEntryModal.scss";
import styleUtils from "../styles/style-utils.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { Column } from "../layout/Column";
import { FormattedMessage } from "react-intl";

export function SatelliteCustomEntryModal({
  appName,
  title,
  subtitleA,
  subtitleB,
  logoSrc,
  className,
  roomName,
  showJoinRoom,
  onJoinRoom,
  showEnterOnDevice,
  onEnterOnDevice,
  showSpectate,
  onSpectate,
  showOptions,
  onOptions,
  ...rest
}) {
  const breakpoint = useCssBreakpoints();
  const useLarge = breakpoint !== "sm" && breakpoint !== "md";
  const useSmall = breakpoint === "sm";
  const useMed = breakpoint === "md";
  const locale = window.SAT.Utils.locale;
  const isFrench = window.SAT.Utils.locale === "fr";
  const isEnglish = window.SAT.Utils.locale === "en"; 
  return (
    <div className={styles.satellite}>
      <div className={styles.satellitesplashrow}>
        <div id="customEntryTitle" className={styles.sattexttitle}>
          <img draggable="false" src="../../assets/logoSAT_second.png" className={styles.disableselection} />
        </div>
        <div id="rightColumn" className={styles.rightcolum}>
          <div id="customEntrySubtitle" className={styles.sattextsub}>
            {isFrench && (
              <img draggable="false" src="../../assets/customEntryTitle_fr.png" className={styles.disableselection} />
            )}
            {isEnglish && (
              <img draggable="false" src="../../assets/customEntryTitle_en.png" className={styles.disableselection} />
            )}
          </div>
          {showJoinRoom && (
            <Button size="small" onClick={onJoinRoom} className={styles.customentrybutton}>
              <span>
                <FormattedMessage id="satellite-room-entry.join-room-button" defaultMessage="Join Room" />
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className={styles.overlay} />
    </div>

    /*{useLarge && (
        <div id="customEntrySubtitle" className={styles.sattextsub}>
          <img draggable="false" src="../../assets/satellitesubtitle.png" className={styles.disableselection} />
        </div>
      )}
      {useMed && (
        <div id="customEntrySubtitle" className={styles.sattextsub}>
          <img draggable="false" src="../../assets/satellitesubtitlesm.png" className={styles.disableselection} />
        </div>
      )}
      {useSmall && (
        <div id="customEntrySubtitle" className={styles.sattextsub}>
          <img draggable="false" src="../../assets/satellitesubtitlesm.png" className={styles.disableselection} />
        </div>
      )}
    */
    /*
    <Modal className={classNames(styles.roomEntryModal, className)} disableFullscreen {...rest}>
      <Column center className={styles.content}>
        {breakpoint !== "sm" &&
          breakpoint !== "md" && (
            <div className={styles.logoContainer}>
              <img src={logoSrc} alt={appName} />
            </div>
          )}
        <div className={styles.roomName}>
          <h5>
            <FormattedMessage id="room-entry-modal.room-name-label" defaultMessage="Room Name" />
          </h5>
          <p>{roomName}</p>
        </div>
        <Column center className={styles.buttons}>
          {showJoinRoom && (
            <Button preset="accent4" onClick={onJoinRoom}>
              <EnterIcon />
              <span>
                <FormattedMessage id="room-entry-modal.join-room-button" defaultMessage="Join Room" />
              </span>
            </Button>
          )}
          {showEnterOnDevice && (
            <Button preset="accent5" onClick={onEnterOnDevice}>
              <VRIcon />
              <span>
                <FormattedMessage id="room-entry-modal.enter-on-device-button" defaultMessage="Enter On Device" />
              </span>
            </Button>
          )}
          {showSpectate && (
            <Button preset="accent2" onClick={onSpectate}>
              <ShowIcon />
              <span>
                <FormattedMessage id="room-entry-modal.spectate-button" defaultMessage="Spectate" />
              </span>
            </Button>
          )}
          {showOptions &&
            breakpoint !== "sm" && (
              <>
                <hr className={styleUtils.showLg} />
                <Button preset="transparent" className={styleUtils.showLg} onClick={onOptions}>
                  <SettingsIcon />
                  <span>
                    <FormattedMessage id="room-entry-modal.options-button" defaultMessage="Options" />
                  </span>
                </Button>
              </>
            )}
        </Column>
      </Column>
    </Modal> */
  );
}

SatelliteCustomEntryModal.propTypes = {
  appName: PropTypes.string,
  title: PropTypes.string,
  subtitleA: PropTypes.string,
  subtitleB: PropTypes.string,
  logoSrc: PropTypes.string,
  className: PropTypes.string,
  roomName: PropTypes.string.isRequired,
  showJoinRoom: PropTypes.bool,
  onJoinRoom: PropTypes.func,
  showEnterOnDevice: PropTypes.bool,
  onEnterOnDevice: PropTypes.func,
  showSpectate: PropTypes.bool,
  onSpectate: PropTypes.func,
  showOptions: PropTypes.bool,
  onOptions: PropTypes.func
};

SatelliteCustomEntryModal.defaultProps = {
  showJoinRoom: true,
  showEnterOnDevice: true,
  showSpectate: true,
  showOptions: true
};
