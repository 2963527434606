// perf issues:
// - rendering the scene with cubeCamera is heavy: to obtain the equirect image, we render cubeCamera to a quad texture, use a camera pointed to the quad that renders to a WebGLRenderTarget, then blit the render target to a canvas
//   tested: directly read and blit cubeCamera renderTarget: readRenderTargetPixels does not support WebGLCubeRenderTarget
//   tested: use second renderer to skip blit to canvas: cannot render scene or quad texture from second renderer
//   not tested, requires quad trick: expand canvas, draw offscreen and use Scissor https://stackoverflow.com/questions/30608723/is-it-possible-to-enable-unbounded-number-of-renderers-in-three-js/30633132#30633132
//   not tested, requires quad trick: expand canvas, draw directly onto canvas; user has equirect view
// -->> NEEDS TO BE FIXED SINCE THIS HAS BEEN REMOVED : LOOK AT LINE 246 :  import { getAudioFeedbackScale } from "./audio-feedback";

const videoCodec = ["h264", "vp9,opus", "vp8,opus", "vp9", "vp8"].find(
  codec => window.MediaRecorder && MediaRecorder.isTypeSupported(`video/webm; codecs=${codec}`)
);
const videoMimeType = videoCodec ? `video/webm; codecs=${videoCodec}` : null;

const vertexShader = [
  "attribute vec3 position;",
  "attribute vec2 uv;",
  "uniform mat4 projectionMatrix;",
  "uniform mat4 modelViewMatrix;",
  "varying vec2 vUv;",
  "void main()  {",
  "  vUv = vec2( 1.- uv.x, uv.y );",
  "  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
  "}"
].join("\n");

const fragmentShader = [
  "precision mediump float;",
  "uniform samplerCube map;",
  "varying vec2 vUv;",
  "#define M_PI 3.141592653589793238462643383279",
  "void main() {",
  "  vec2 uv = vUv;",
  "  float longitude = uv.x * 2. * M_PI - M_PI;",
  "  float latitude = uv.y * M_PI;",
  "  vec3 dir = vec3(",
  "    - sin( longitude ) * sin( latitude ),",
  "    cos( latitude ),",
  "    - cos( longitude ) * sin( latitude )",
  "  );",
  "  normalize( dir );",
  "  gl_FragColor = vec4( textureCube( map, dir ).rgb, 1.0 );",
  "}"
].join("\n");

const hiddenObjects = [];

AFRAME.registerComponent("equirectangular-viewer", {
  schema: {
    // stream
    fps: { default: 25 },
    captureAudio: { default: true },
    bitsPerSecond: { default: 6000000 },
    // cubeCamera
    resolution: { default: 1024 },
    near: { default: 0.01 },
    far: { default: 1000 },
    // hide these items in rendered view
    hideTargets: { type: "selectorAll", default: "#avatar-rig" }
  },
  async init() {
    // TODO can we use cubecam rendertarget directly
    // TODO disable antialias? - cannot do dynamically
    // TODO implement video stream
    // TODO make simple ui
    const avatarRig = document.getElementById("avatar-rig");
    this.cameraSystem = this.el.sceneEl.systems["camera-tools"];
    for (const el of this.data.hideTargets) {
      if (el === avatarRig) {
        this.noUnhideHead = true;

        break;
      }
    }

    if (this.data.fps) {
      this.tick = AFRAME.utils.throttleTick(this._tick, 1000 / this.data.fps, this);
    } else {
      this.tick = this._tick;
    }

    this.renderer = this.el.sceneEl.renderer;
    const gl = this.renderer.getContext();
    const size = Math.min(gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE), this.data.resolution);
    this.width = size;
    this.height = size / 2;

    // cubeCamera
    // threejs 128
    this.cubeCamera = new THREE.CubeCamera(this.data.near, this.data.far, size);
    // threejs 133
    /*const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(size, {
      format: THREE.RGBFormat,
      generateMipmaps: true,
      minFilter: THREE.LinearMipmapLinearFilter,
      encoding: THREE.sRGBEncoding
    });
    this.cubeCamera = new THREE.CubeCamera(this.data.near, this.data.far, cubeRenderTarget);
    */
    this.el.object3D.add(this.cubeCamera);

    // scene with quad only
    this.renderScene = new THREE.Scene();

    // quad which texture is the equirect projection from cubeCamera
    this.material = new THREE.RawShaderMaterial({
      uniforms: {
        map: { type: "t", value: null }
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: THREE.FrontSide
    });
    this.quad = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1), this.material);
    this.quad.scale.set(this.width, this.height, 1);
    this.quad.material.uniforms.map.value = this.cubeCamera.renderTarget.texture;
    this.renderScene.add(this.quad);

    // quad viewer camera
    this.renderCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -10000, 10000);
    this.renderCamera.left = this.width / -2;
    this.renderCamera.right = this.width / 2;
    this.renderCamera.top = this.height / 2;
    this.renderCamera.bottom = this.height / -2;
    this.renderCamera.updateProjectionMatrix();

    // buffer to store quad rendered view before blitting to canvas
    this.renderTarget = new THREE.WebGLRenderTarget(this.width, this.height, {
      encoding: THREE.sRGBEncoding,
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      wrapS: THREE.ClampToEdgeWrapping,
      wrapT: THREE.ClampToEdgeWrapping,
      format: THREE.RGBAFormat,
      type: THREE.UnsignedByteType
    });

    // dedicated canvas for equirect view
    this.videoCanvas = document.createElement("canvas");
    this.videoCanvas.width = this.width;
    this.videoCanvas.height = this.height;
    this.videoContext = this.videoCanvas.getContext("2d");
    this.videoImageData = this.videoContext.createImageData(this.width, this.height);
    this.videoPixels = new Uint8Array(this.width * this.height * 4);
    this.videoImageData.data.set(this.videoPixels);

    //begin recording - adapted from camera-tool
    this.stream = new MediaStream();
    const track = this.videoCanvas.captureStream(this.data.fps || 15).getVideoTracks()[0];
    const attachBlankAudio = () => {
      const context = THREE.AudioContext.getContext();
      const oscillator = context.createOscillator();
      const gain = context.createGain();
      const destination = context.createMediaStreamDestination();
      gain.gain.setValueAtTime(0.0001, context.currentTime);
      oscillator.connect(destination);
      gain.connect(destination);
      this.stream.addTrack(destination.stream.getAudioTracks()[0]);
    };
    if (this.data.captureAudio) {
      const selfAudio = await APP.dialog.getMediaStream(NAF.clientId, "audio");
      if (selfAudio && selfAudio.getAudioTracks().length > 0) {
        const context = THREE.AudioContext.getContext();
        const destination = context.createMediaStreamDestination();
        const listener = this.el.sceneEl.audioListener;
        if (listener) listener.getInput().connect(destination);
        context.createMediaStreamSource(selfAudio).connect(destination);
        const audio = destination.stream.getAudioTracks()[0];
        this.stream.addTrack(audio);
      } else {
        attachBlankAudio();
      }
    } else {
      attachBlankAudio();
    }
    this.stream.addTrack(track);
  },
  snapshot() {
    const im = new Image();
    im.src = this.videoCanvas.toDataURL("image/png", 1);
    window.open("", "_blank").document.write(im.outerHTML);
  },
  video() {
    // display stream in popup
    const w = window.open("", "_blank", "x=y");
    const videoEl = document.createElement("video");
    videoEl.autoplay = true;
    videoEl.muted = true;
    w.document.body.appendChild(videoEl).srcObject = this.stream;
  },
  startRecording() {
    if (this.videoRecorder) return;
    if (!this.chunks) this.chunks = [];
    this.videoRecorder = new MediaRecorder(this.stream, {
      mimeType: videoMimeType,
      bitsPerSecond: this.data.bitsPerSecond
    });
    this.videoRecorder.ondataavailable = e => {
      this.chunks.push(e.data);
      if (!this.videoRecorder) {
        // recording was stopped
        const mimeType = this.chunks[0].type;
        const blob = new Blob(this.chunks, { type: mimeType });
        this.chunks.length = 0;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download =
          Math.random()
            .toString(32)
            .split(".")[1] + ".webm";
        link.click();
      }
    };
    this.videoRecorder.start();
    this.el.sceneEl.emit("action_camera_recording_started");
  },
  stopRecording() {
    if (!this.videoRecorder) return;
    this.videoRecorder.stop();
    this.videoRecorder = null;
    this.el.sceneEl.emit("action_camera_recording_ended");
  },
  _tick() {
    // if tick is throttled, use this to throttle tock
    this.hasTickedThisFrame = true;
  },
  tock() {
    if (!this.hasTickedThisFrame) return;
    this.hasTickedThisFrame = false;

    hiddenObjects.length = 0;
    for (let i = this.data.hideTargets.length - 1; i >= 0; i--) {
      const ob = this.data.hideTargets[i].object3D;
      if (ob?.visible) {
        ob.visible = false;
        hiddenObjects.push(ob);
      }
    }
    let playerHead, bubbleSystem, boneVisibilitySystem;
    if (!this.noUnhideHead) {
      playerHead = this.cameraSystem.playerHead;
      if (playerHead) {
        // We want to scale our own head in between frames now that we're taking a video/photo.
        let scale = 1;
        const analyser = this.el.sceneEl.systems["local-audio-analyser"];
        if (analyser && playerHead.el.components["scale-audio-feedback"]) {
          scale = 1.0; //getAudioFeedbackScale(this.el.object3D, playerHead, 1, 2, analyser.volume);
        }
        playerHead.visible = true;
        playerHead.scale.set(scale, scale, scale);
        playerHead.updateMatrices(true, true);
        playerHead.updateMatrixWorld(true, true);
      }
      bubbleSystem = this.el.sceneEl.systems["personal-space-bubble"];
      boneVisibilitySystem = this.el.sceneEl.systems["hubs-systems"].boneVisibilitySystem;
      if (bubbleSystem) {
        for (let i = 0, l = bubbleSystem.invaders.length; i < l; i++) {
          bubbleSystem.invaders[i].disable();
        }
        // HACK, bone visibility typically takes a tick to update, but since we want to be able
        // to have enable() and disable() be reflected this frame, we need to do it immediately.
        boneVisibilitySystem.tick();
      }
    }

    // render scene through cubeCamera onto quad texture
    if (!this.renderer.autoClear) {
      this.renderer.autoClear = true;
      this.renderer.clear();
      this.cubeCamera.update(this.renderer, this.el.sceneEl.object3D);
      this.renderer.autoClear = false;
    } else {
      this.cubeCamera.update(this.renderer, this.el.sceneEl.object3D);
    }

    for (const ob of hiddenObjects) ob.visible = true;
    if (!this.noUnhideHead) {
      if (playerHead) {
        playerHead.visible = false;
        playerHead.scale.set(0.00000001, 0.00000001, 0.00000001);
        playerHead.updateMatrices(true, true);
        playerHead.updateMatrixWorld(true, true);
      }
      if (bubbleSystem) {
        for (let i = 0, l = bubbleSystem.invaders.length; i < l; i++) {
          bubbleSystem.invaders[i].enable();
        }
        // HACK, bone visibility typically takes a tick to update, but since we want to be able
        // to have enable() and disable() be reflected this frame, we need to do it immediately.
        boneVisibilitySystem.tick();
      }
    }

    // render quad viewer camera into buffer
    this.renderer.setRenderTarget(this.renderTarget);
    this.renderer.render(this.renderScene, this.renderCamera);
    this.renderer.setRenderTarget(null);
    // this.renderer.readRenderTargetPixels(this.cubeCamera.renderTarget, 0, 0, this.width, this.height, this.videoPixels); // doesn't work with cubeCamera's renderTarget
    // blit buffer to canvas
    this.renderer.readRenderTargetPixels(this.renderTarget, 0, 0, this.width, this.height, this.videoPixels);
    this.videoImageData.data.set(this.videoPixels);
    this.videoContext.putImageData(this.videoImageData, 0, 0);
    // if (this.getNextFrame) {
    //   this.getNextFrame = false;
    //   this.dl();
    // }
  }
});
