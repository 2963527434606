import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./SpacesMediaGrid.scss";

export function SpacesMediaGrid({ children, className, center, isVariableWidth, sm, ...rest }) {
  return (
    <div
      className={classNames(
        styles.mediaGrid,
        { [styles.center]: center },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

SpacesMediaGrid.propTypes = {
  center: PropTypes.bool,
  isVariableWidth: PropTypes.bool,
  sm: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
};
