import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";
import styles from "./SpacesMenuMediaTiles.scss";
import { ReactComponent as PeopleIcon } from "../icons/People2.svg";
import { ReactComponent as StarIcon } from "../icons/Star.svg";
import { ReactComponent as AddIcon } from "../icons/Add.svg";
import { ReactComponent as PenIcon } from "../icons/Pen.svg";
import { ReactComponent as DuplicateIcon } from "../icons/Duplicate.svg";
import { ReactComponent as SearchIcon } from "../icons/Search.svg";
import { ReactComponent as HelpIcon } from "../icons/Help.svg";
import { ReactComponent as ExternalLinkIcon } from "../icons/ExternalLink.svg";

const PUBLISHER_FOR_ENTRY_TYPE = {
  sketchfab_model: "Sketchfab",
  twitch_stream: "Twitch"
};

const Categories = {
  Art: 'ART',
  VS: 'SAT',
  Experience: 'EXPERIENCE',
  lab: 'LAB',
  Campus: 'CAMPUS',
  Other: 'OTHER'
};


function useThumbnailSize(isImage, isAvatar, imageAspect) {
  return useMemo(
    () => {
      let imageHeight = 220;
      if (isAvatar) imageHeight = Math.floor(imageHeight * 1.5);

      // Aspect ratio can vary per image if its an image result. Avatars are a taller portrait aspect, o/w assume 720p
      let imageWidth;
      if (isImage) {
        imageWidth = Math.floor(Math.max(imageAspect * imageHeight, imageHeight * 0.85));
      } else if (isAvatar) {
        imageWidth = Math.floor((9 / 16) * imageHeight);
      } else {
        imageWidth = Math.floor(Math.max((16 / 9) * imageHeight, imageHeight * 0.85));
      }

      return [imageWidth, imageHeight];
    },
    [isImage, isAvatar, imageAspect]
  );
}

function useThumbnail(entry, processThumbnailUrl) {
  const isImage = entry.type.endsWith("_image");
  const isAvatar = entry.type === "avatar" || entry.type === "avatar_listing";
  const imageAspect = entry.images.preview.width / entry.images.preview.height;

  const [thumbnailWidth, thumbnailHeight] = useThumbnailSize(isImage, isAvatar, imageAspect);

  const thumbnailUrl = useMemo(
    () => {
      return processThumbnailUrl
        ? processThumbnailUrl(entry, thumbnailWidth, thumbnailHeight)
        : entry.images.preview.url;
    },
    [entry, thumbnailWidth, thumbnailHeight, processThumbnailUrl]
  );

  return [thumbnailUrl, thumbnailWidth, thumbnailHeight];
}

function BaseTile({ as: TileComponent, className, tall, wide, children, category, ...rest }) {
  let additionalProps;
  // const category = "OTHER";

  if (TileComponent === "div") {
    additionalProps = {
      tabIndex: "0",
      role: "button"
    };
  }

  return (
    <TileComponent
      className={classNames(styles.SpaceTile, { [styles.tall]: tall, [styles.wide]: wide }, className)}
      {...additionalProps}
      {...rest}
    >
      <div className={styles.category} 
      style= //TODO refactor 
        {{
        "backgroundColor": 
          `${
            category == "SAT" ? '#7bbfb1' : 
            category == "CAMPUS" ? '#d43f6f' :  
            category == "ART" ? '#1e0453' : 
            category == "EXPERIENCE" ? '#5059e1' :
            category == "LAB" ? '#e7e369' : "white" 
          }`,
          "color": 
          `${
            (category == "CAMPUS" || category == "EXPERIENCE" || category == "ART" ) ? 'white' : 'black'
          }`,
        }}>
          <span className={styles.categoryText}>
            {category}
          </span>
      </div>
      <div className={styles.thumbnailContainer}>{children}</div>
    </TileComponent>
  );
}

BaseTile.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  tall: PropTypes.bool,
  wide: PropTypes.bool,
  category: PropTypes.string,
};

BaseTile.defaultProps = {
  as: "div"
};

function TileAction({ className, children, ...rest }) {
  return (
    <button type="button" className={classNames(styles.tileAction, className)} {...rest}>
      {children}
    </button>
  );
}

TileAction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export function SpaceTile({ entry, processThumbnailUrl, onClick, onEdit, onShowSimilar, onCopy, onInfo, ...rest }) {
  console.log(entry);
  const intl = useIntl();
  const creator = entry.attributions && entry.attributions.creator;
  const publisherName =
    (entry.attributions && entry.attributions.publisher && entry.attributions.publisher.name) ||
    PUBLISHER_FOR_ENTRY_TYPE[entry.type];

  const [thumbnailUrl, thumbnailWidth, thumbnailHeight] = useThumbnail(entry, processThumbnailUrl);
  const name = entry.name;
  let category = "OTHER";

  if(entry.description!=undefined && entry.description.split('#')[1]!=undefined) category = entry.description.split('#')[1];

  return (
    <BaseTile
      href={entry.url} rel="noreferrer noopener" onClick={onClick}
      wide={entry.type === "room"}
      name={entry.name}
      category={category}
      description={
        <>
          {(entry.type === "room") && <span>&nbsp;</span>}
        </>
      }
      {...rest}
    >
     {entry.name !== undefined && (
        <div className={styles.info}>
          <span>{entry.name}</span>
        </div>
      )}
      {(entry.description !== undefined && entry.description !== null) && (
        <div className={styles.description}>
          <span>{entry.description.split('#')[0]}</span>
        </div>
      )}
      {entry.favorited && <StarIcon className={styles.favoriteIcon} />}
      {entry.member_count !== undefined && (
        <div className={styles.memberCount}>
          <PeopleIcon /> <span style={{fontSize:"180%"}}>{entry.member_count} </span> 
          <span style={{fontSize:"80%",fontWeight:'bold',paddingBottom:"-10%"}}> {"/" + entry.room_size}</span>
        </div>
      )}
      {/* {category !== undefined && (
        <div className={styles.category}>
          <span>{category}</span>
        </div>
      )} */}
    </BaseTile>
  );
}

SpaceTile.propTypes = {
  entry: PropTypes.object.isRequired,
  processThumbnailUrl: PropTypes.func,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onShowSimilar: PropTypes.func,
  onCopy: PropTypes.func,
  onInfo: PropTypes.func
};
