import React from "react";
import styles from "./SATCustomSpinner.scss";

export function SATCustomSpinner() {
  return (
    <div className={styles.loader}>
      <svg className={styles.circularloader} viewBox="25 25 50 50">
        <circle className={styles.loaderpath} cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
}
